export const zyephrMenu = [
  {
    key: 'dashboard',
    title: 'Dashboard',
    route: '/main/dashboard',
    icon: 'dashboard',
    children: [],
  },
  {
    key: 'users',
    title: 'Users',
    route: '/main/users',
    icon: 'user',
    children: [
      {
        key: 'users',
        title: 'Users',
        route: '/main/users/list',
        icon: 'user',
        children: [],
      },
      {
        key: 'delete-request',
        title: 'Delete Requests',
        route: '/main/users/delete-request',
        icon: 'delete',
        children: [],
      }
    ],
  },
  {
    key: 'team',
    title: 'Team Member',
    route: '/main/team-member',
    icon: 'team',
    children: [],
  },
  {
    key: 'category',
    title: 'Category',
    route: '/main/category',
    icon: 'team',
    children: [],
  },
  {
    key: 'sub-category',
    title: 'Sub Category',
    route: '/main/sub-category',
    icon: 'team',
    children: [],
  },
  {
    key: 'career',
    title: 'Career',
    route: '/main/career',
    icon: 'container',
    children: [],
  },

  {
    key: 'contactUs',
    title: 'Contact Us',
    route: '/main/contact-us',
    icon: 'contacts',
    children: [],
  },
  {
    key: 'banner',
    title: 'Banner',
    route: '/main/banner',
    icon: 'paper-clip',
    children: []
  },
  {
    key: 'blog',
    title: 'Blog',
    route: '',
    icon: 'book',
    children: [
      {
        key: 'mobile',
        title: 'Mobile',
        route: '/main/blog/mobile',
        children: [],
      },
      {
        key: 'web',
        title: 'Web',
        route: '/main/blog/web',
        children: [],
      },
    ],
  },
  {
    key: 'pregnancy-content',
    title: 'Pregnancy',
    route: '',
    icon: 'paper-clip',
    children: [
      {
        key: 'pregnancy-content',
        title: 'Pregnancy Content',
        route: '/main/pregrancy/pregrancy-content',
        icon: 'paper-clip',
        children: []
      },
      {
        key: 'pregnancy-stories',
        title: 'Pregnancy Stories',
        route: '/main/pregrancy/pregrancy-stories',
        icon: 'paper-clip',
        children: []
      }
    ]
  },
  {
    key: 'symptom',
    title: 'Symptom',
    route: '/main/symptom',
    icon: 'paper-clip',
    children: []
  },
  {
    key: 'medication-purpose',
    title: 'Medication Purpose',
    route: '/main/medication-purpose',
    icon: 'paper-clip',
    children: []
  },
  {
    key: 'trigger',
    title: 'Trigger',
    route: '/main/trigger',
    icon: 'paper-clip',
    children: []
  },
  {
    key: 'recipe',
    title: 'Recipe',
    route: '/main/recipe',
    icon: 'paper-clip',
    children: []
  },

  {
    key: 'vaccine',
    title: 'Vaccinne Schedule',
    route: '/main/vaccine-schedule',
    icon: 'paper-clip',
    children: []
  },

  {
    key: 'community',
    title: 'Community',
    route: '/main/community',
    icon: 'comment',
    children: [
      {
        key: 'communityTopic',
        title: 'Community Topic',
        route: '/main/community/community-topic',
        children: []
      },
      {
        key: 'feed',
        title: 'Feed',
        route: '/main/community/feed',
        children: [],
      },
    ],
  },
  {
    key: 'reporting',
    title: 'Reporting',
    route: '/main/reporting',
    icon: 'area-chart',
    children: [
      {
        key: 'userWise',
        title: 'User Wise',
        route: '/main/reporting/user',
        children: [],
      },
      {
        key: 'trafficMonitoring',
        title: 'Traffic Monitoring',
        route: '/main/reporting/traffic-monitoring',
        children: [],
      }
    ],
  },
  {
    key: 'deep-linking',
    title: 'Deep Linking',
    route: '/main/deep-linking',
    icon: 'area-chart',
    children: [
      {
        key: 'campaign',
        title: 'Campaign',
        route: '/main/campaign',
        children: [],
      },
      {
        key: 'deepLinking',
        title: 'Deep Linking',
        route: '/main/deep-linking',
        children: [],
      }

    ],
  }

];
